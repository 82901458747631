.frame-online-nachhilfe {
  margin-top: 30px;
  font-size: 14px;

  .ce-bodytext {
    padding: 20px;
    background-color: #F6F6F6;
    border: 1px solid #E3E3E3;

    p:last-child {
      margin-bottom: 0;
    }
  }

  header {
    display: flex;
    align-items: center;
    background-color: #d7031c;
    padding: 8px 20px;

    h2 {
      font-family: "Open Sans Condensed", sans-serif;
      font-size: 30px;
      color: #fff;
      margin-bottom: 0;
      font-weight: bold;
    }

    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 50px;
      background-image: url('/fileadmin/bilder/check.png');
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 15px;

      @media screen and (min-width: 801px) {
        display: none;
      }

      @media screen and (min-width: 1024px) {
        display: block;
      }
    }
  }
}
