/* update typo3 9 */
.frame header h1 + h2,
.frame header h2 + h3,
.frame header h3 + h4,
.frame header h4 + h5,
.frame header h5 + h6 {
  font-size: 1.667em;
  color: #0082c7;
  font-family: 'Lobster', cursive;
  margin-top: -0.4em;
  margin-bottom: 1em;
  line-height: 0.9em;
}

.btn {
  background: white;
  color: black;
  font-family: open sans condensed, sans-serif;
  font-size: 1em;
  margin: 0;
  text-transform: uppercase;
  width: 50%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  transition: all 0.2s ease 0s;
  padding: 0.6em 0.8em;
}

.btn-primary {
  background: none repeat scroll 0 0 #d7031c;
  color: #fff;
}

.form-control {
  width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  width: auto;
  margin-right: 10px;
  margin-left: 5px;
}

.frame-type-form_formframework {
  label {
    display: none;
  }

  form {
    h1, h2, h3 {
      display: none;
    }
  }

  .radio, .checkbox {
    label {
      display: block;
    }
  }

  legend {
    color: #0082c7;
    font-family: 'Lobster', cursive;
    font-size: 1.688em;
    line-height: 1.2em;
    margin-bottom: 0.667em;
    margin-top: 0;
  }

  fieldset {
    margin-bottom: 10px;

    > .form-group {
      background: none repeat scroll 0 0 #f6f6f6;
      border: 1px solid #e3e3e3;
      font-size: 0.833em;
      padding: 1em;
      width: 100%;
    }
  }

  .ce-bodytext p:last-child {
    margin-bottom: 0;
  }
}

#bewerbungsformular-49-fieldset-1,
#bewerbungsformular-49-fieldset-2 {
  > .form-group {
    background: none transparent;
    border: none;
    padding: 0;
    font-size: 1rem;
  }
}

#bewerbungsformular-49-fieldset-5 {
  margin-bottom: 2rem;
}

#c104 {
  font-size: 13px;
  margin-bottom: 10px;
}

#kontaktformular-10 {
  .actions {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .btn-primary {
    float: right;
    @media (max-width: 800px) {
      float: none;
    }
  }
}

// parsley extra
.form-group {
  position: relative;

  &.has-error {
    border-color: red !important;

    .form-control {
      border-color: red !important;

      &:focus {
        outline-color: red !important;
      }
    }
  }
}

.content .parsley-errors-list,
.parsley-errors-list {
  position: absolute;
  right: 0;
  bottom: 100%;
  transform: translateX(10px) translateY(10px);
  list-style: none;
  margin: 0;
  @media (max-width: 800px) {
    transform: translateX(0px) translateY(10px);
  }

  li {
    display: block;
    margin: 0;
    background: red;
    color: #FFF;
    font-size: 12px;
    line-height: 13px;
    padding: 5px;

    &:before {
      content: none;
      display: none;
    }
  }
}
