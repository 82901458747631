*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  background: none repeat scroll 0 0 #fff;
  color: #707070;
  font-family: 'Open Sans', sans-serif;
  font-size: 17.6px;
  line-height: 1.5rem;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

.content p, .rechte-spalte p {
  margin-bottom: 1rem;
  line-height: 1.45rem;
}

.content .ce-bodytext p:last-child,
.rechte-spalte .ce-bodytext p:last-child {
  //margin-bottom: 0;
}

a {
  text-decoration: none;
  color: rgba(215, 3, 28, 1);
}

a:hover {
  text-decoration: none;
  color: rgba(215, 3, 28, 0.75);
}

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

sup {
  vertical-align: super;
  font-size: 80%;
}

sub {
  vertical-align: sub;
  font-size: 80%;
}

s, del {
  text-decoration: line-through;
}

.clear {
  clear: both;
  float: none;
}

#mobile-header {
  display: none;
}

h1, h2, h3, h4, h5 {
  color: #0082c7;
  font-family: 'Lobster', cursive;
  font-size: 2.833em;
  line-height: 1.2em;
  margin-bottom: 0.3em;
}

h1.subheadline, .csc-subheader {
  font-size: 1.667em;
  color: #0082c7;
  font-family: 'Lobster', cursive;
  margin-top: -0.4em;
}

h2, h3, h4, h5 {
  font-size: 1.688em;
  margin-bottom: 0.667em;
}

.header-wrap {
  margin: 0 auto;
  max-width: 1170px;
  width: 97.4359%;
  position: relative;
}

.header {
  padding-bottom: 25px;
  overflow: hidden;
}

.google-translate-element {
  position: absolute;
  bottom: 18px;
  right: 15px;
}

.goog-te-gadget img {
  display: inline-block;
}

.goog-te-gadget-simple {
  background-color: #F6F6F6 !important;
  border: 1px solid #E3E3E3 !important;
  position: relative;
  padding: 6px 40px 6px 5px !important;
}

.goog-te-menu-value span:first-child {
  color: #8B8B8B;
  display: inline-block;
  padding: 0 5px 0 0;
}

.goog-te-menu-value img,
.goog-te-menu-value span:nth-child(3) {
  display: none;
}

.goog-te-menu-value span:last-child {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 42px;
  background: #D7061C;
  border: 1px solid #D7061C;
  color: #D7061C !important;
}

.goog-te-menu-value span:last-child:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: white;
  background: url(/fileadmin/site/Public/img/chevron-down-solid.svg) no-repeat center center;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.goog-te-menu-frame {
  top: 140px !important;
}

@media (max-width: 1069.98px) {
  .google-translate-element {
    position: absolute;
    top: 15px;
    bottom: auto;
    right: 15px;
  }
}

@media (max-width: 800.98px) {
  .goog-te-menu-value span:first-child {
    color: #8B8B8B;
    display: inline-block;
    padding: 0 5px 0 0;
    word-spacing: 10px;
    white-space: nowrap;
    width: 55px;
    overflow: hidden;
    vertical-align: middle;
  }
}

.rettungsring {
  margin-right: 7%;
  margin-top: 3%;
  position: absolute;
  right: 0;
  width: 8%;
  z-index: 10;
}

.logo {
  float: left;
  padding-top: 1.444em;
  width: 10.625rem;
}

.navi {
  color: #707070;
  float: right;
  font-family: open sans condensed;
  font-size: 0.93em;
  margin-top: 58px;
  text-transform: uppercase;
}

.navi li {
  margin-right: 1.5em;
}

.navi li:last-child {
  margin-right: 0;
}

.navi a {
  color: #707070;
  padding: 0.6em 1em;
  transition: all 0.2s ease 0s;
}

.navi a:hover {
  background: none repeat scroll 0 0 #d7031c;
  border-radius: 5px;
  color: #fff;
  padding: 0.6em 1em;
}

.slider-wrap {
  background: #0082c7;
}

.flexslider > img {
  margin: 0 auto;
}

.slider.loading .slides > li:first-child {
  opacity: 1 !important;
}

.boxen {
  margin: 0 auto;
  max-width: 1170px;
  padding: 1.289rem 0;
  width: 97.4359%;
}

.boxen-wrap {
  background: none repeat scroll 0 0 #eaeaea;
  margin-bottom: 2.444em;
  overflow: hidden;
}

.box1, .box2, .box3, .box4 {
  background: #d7031c;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.111em;
  margin: 0 1.28205%;
  text-transform: uppercase;
  width: 22.4786%;
}

.boxen .ce-gallery .ce-row:last-child .ce-column:last-child img {
  /*width: 100%;*/
}

.boxen .ce-bodytext {
  float: right;
  margin: 0;
  padding-right: 10%;
  width: 61%;
}

.boxen .ce-gallery {
  width: 25%;
}

.ce-textpic,
.ce-image,
.ce-nowrap .ce-bodytext,
.ce-gallery,
.ce-row,
.ce-uploads li,
.ce-uploads div {
  overflow: visible;
}

.boxen .ce-intext.ce-right .ce-gallery,
.boxen .ce-intext.ce-left .ce-gallery,
.boxen .ce-above .ce-gallery {
  margin-bottom: 0;
}

.box1 p, .box2 p, .box3 p, .box4 p {
  margin-top: 1.2em;
}

.box1 img, .box2 img, .box3 img, .box4 img {
  float: left;
  margin: 0.8em 0 0.8em 1em;
}

.box1 {
  margin-left: 0;
}

.box4 {
  margin-right: 0;
}

.mitte {
  display: flex;
  margin: 0 auto 2em;
  max-width: calc(1170px + 2.5641%);
  //overflow: hidden;
  padding: 0 1.28205%;
  width: 100%;
}

.content {
  flex: 0 0 62.103%;
  max-width: 62.103%;
  margin-right: 4.564%;
}

.rechte-spalte {
  flex: 0 0 30.7692%;
  max-width: 30.7692%;
}

.rechte-spalte h2 {
  font-size: 1.667em;
}

.footer {
  color: #fff;
  font-size: 0.833em;
  line-height: 1.4em;
  margin: 0 auto;
  max-width: 1170px;
  overflow: hidden;
  padding-bottom: 70px;
  padding-top: 50px;
  width: 97.4359%;
}

.footer li {
  display: block;
}

.footer-wrap {
  background: none repeat scroll 0 0 #0082c7;
  clear: both;
}

.footer-1 {
  float: left;
  margin-right: 2.5%;
  width: 24.5%;
}

.footer-2 {
  margin-right: 2.5%;
  width: 14.9%;
  float: left;
}

.footer-3 {
  margin-right: 2.5%;
  width: 14.9%;
  float: left;
}

.footer-4 {
  margin-right: 2.5%;
  width: 14.9%;
  float: left;
}

.footer-5 {
  margin-right: 1.6666666666666667%; /* 19 / 1140 */
  width: 9%;
  float: left;
}

.footer-6 {
  margin-right: 0;
  width: 9%;
  float: left;
}

.footer-6 img {
  max-width: 100%;
  height: auto;
}

.footer-6 small {
  display: block;
  font-size: 0.8em;
  margin-bottom: 3px;
}

.footer h3 {
  color: #fff;
  font-size: 1.333em;
  margin-bottom: 0.2em;
}

.footer a {
  color: #fff;
  transition: all 0.2s ease 0s;
}

.footer a:hover {
  color: #bde8f4;
}

.footer li:before {
  color: #fff;
  content: "»";
  margin-right: 0.2em;
}

li {
  display: inline-block;
}

.antispamimg {
  display: inline;
}

.form-control {
  background: none repeat scroll 0 0 #f6f6f6;
  border: 1px solid #e3e3e3;
  color: #8b8b8b;
  font-size: 0.833em;
  margin-bottom: 0.5em;
  padding: 0.6em 0.8em;
  width: 100%;
}

.powermail_field.powermail_submit {
  background: none repeat scroll 0 0 #d7031c;
  color: #fff;
  float: right;
  font-family: open sans condensed;
  font-size: 1em;
  margin: 0;
  text-transform: uppercase;
  width: 50%;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  transition: all 0.2s ease 0s;
}

.content .powermail_field.powermail_submit {
  float: left;
}

.powermail_field.powermail_submit:hover {
  background: #ac0216;
}

/*Responsive iFrame */
.embed-container {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16x9 */
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ratio 4x3 */
.embed-container.ratio4x3 {
  padding-bottom: 75%;
}

/*Flexslider*/
.flex-viewport {
  z-index: 9; /* unter dem rettungsring (10) */
}

.background-slider-wrap {
  display: block;
  z-index: 0;
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.background-slider {
  float: left;
  display: block;
  width: 100%;
}

.slider .slides > li {
  display: none;
}

.slider .slides > li:first-child {
  /*display: block;*/
}

.slider .slides img {
  margin: 0 auto;
}

.flex-caption p {
  background: #fff;
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.9);
  color: #0082c7;
  font-family: lobster;
  font-size: 1.7em;
  font-size: 1.8vw;
  margin-bottom: 0.4em;
  padding: 0.7em;
  padding: 1vw;
}

.slider .important p:before, .slider .important-mobile p:before {
  content: "";
  font-family: 'FontAwesome';
  padding-right: 0.6em;
}

.slider .important p, .slider .important-mobile p {
  background: none repeat scroll 0 0 #00649a;
  background: linear-gradient(to bottom, rgba(0, 116, 178, 1) 0%, rgba(0, 100, 154, 1) 100%);
  border-radius: 6px;
  color: #fff;
  width: 9em;
  width: 17.2vw;
  -moz-box-shadow: 3px 3px 0px #005c80;
  -webkit-box-shadow: 3px 3px 0px #005c80;
  box-shadow: 3px 3px 0px #005c80;
}

.slider .important p a, .slider .important-mobile p a {
  color: #fff;
}

.flex-direction-nav {
  position: absolute;
  top: 20em;
  width: 100%;
  z-index: 1000;
}

.flex-direction-nav .flex-prev {
  left: 10px;
  opacity: 1;
}

.flex-direction-nav .flex-next {
  opacity: 1;
  right: 10px;
}

.flex-direction-nav a:before {
  content: "\f137";
  display: inline-block;
  font-family: 'FontAwesome';
  font-size: 40px;
  line-height: 1;
}

.flex-direction-nav a.flex-next:before {
  content: "\f138 ";
}

.flex-direction-nav a {
  color: rgba(255, 255, 255, 0.1);
}

.flex-caption {
  left: 15%;
  position: absolute;
  top: 6em;
  z-index: 100;
}

.flex-direction-nav a:before {
  font-size: 33px;
}

.slides > li {
  position: relative;
}

/*Flexslider Ende*/
/*Bewerbungsformular*/
.powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_6,
.powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_8,
.powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_11,
.powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_25,
.powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_26 {
  float: left;
  margin-right: 1.8%;
  width: 47%;
}

.powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_7, .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_10, .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_12 {
  float: left;
  width: 47%;
}

.powermail_fieldwrap.powermail_fieldwrap_radio.powermail_fieldwrap_13 {
  clear: both;
  overflow: hidden;
}

.powermail_label.powermail_radio_legend, .powermail_label {
  color: #0082c7;
  font-family: 'Lobster', cursive;
  font-size: 1.688em;
  line-height: 1.2em;
  margin-bottom: 0.667em;
  margin-top: 1em;
}

.powermail_radio_outer, .powermail_check_outer {
  background: none repeat scroll 0 0 #f6f6f6;
  border: 1px solid #e3e3e3;
  font-size: 0.833em;
  padding: 1em;
  width: 91%;
}

.powermail_radio, input[type="checkbox"] {
  width: 2em;
}

.powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_16 {
  margin-top: 1em;
}

.powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_17 {
  margin-bottom: 1em;
}

.powermail_fieldwrap_file_inner {
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.powermail_form.powermail_form_2 > h3, .content .powermail_form.powermail_form_1 > h3 {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.formErrorContent {
  background: red;
  color: #FFF;
  font-size: 12px;
  line-height: 13px;
  padding: 5px;
}

/*Bewer ende*/
.content .ce-gallery {
  margin-right: 2.5% !important;
  width: 21%;
}

.ce-intext.ce-right .ce-gallery {
  margin-right: 0 !important;
  width: 33%;
}

.ce-border img,
.ce-border iframe {
  border: 1px solid #e3e3e3;
  padding: 2px;
}

.content .frame-default {
  margin-bottom: 1.5rem;
}

/*Rahmen*/
.frame-custom-103,
.linie_danach {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 1.5em;
  padding-bottom: 0.5em;
}

.frame-custom-104,
.box {
  background: none repeat scroll 0 0 #f6f6f6;
  border: 1px solid #e3e3e3;
  margin-bottom: 2em;
}

.frame-custom-104, .box {
  p:first-child {
    margin-top: 1em;
  }

  header ~ .ce-textpic p:first-child {
    margin-top: 0;
  }
}

.frame-custom-104 p, .frame-custom-104 ul,
.box p, .box ul {
  padding: 0 2em;
}

.frame-custom-104 h2,
.box h2 {
  background: none repeat scroll 0 0 #d7031c;
  color: #fff;
  font-family: open sans condensed;
  font-size: 1.3em;
  margin-bottom: 1em;
  padding: 0.5em 1.5em;
  text-transform: uppercase;
}

.content .frame-custom-104 li:before,
.content .box li:before {
  color: #d7031c;
  content: "";
  font-family: 'FontAwesome';
  font-size: 1.1em;
  margin: 0 12px 0 -31px;
}

.frame-custom-105,
.elternmeinung {
  background: none repeat scroll 0 0 #eaeaea;
  font-size: 0.93em;
  margin-bottom: 2em;
  padding: 1.4em;
}

.frame-custom-105 h2, .frame-custom-105 h3, .frame-custom-105 h4, .frame-custom-105 h1,
.elternmeinung h2, .elternmeinung h3, .elternmeinung h4, .elternmeinung h1 {
  font-size: 1.5em;
  margin: 0 0 0.2em;
}

.frame-custom-105,
.elternmeinung {
  i, em {
    color: #b6b6b6;
    font-size: 0.9em;
  }
}

.frame-custom-105 p:last-child,
.elternmeinung p:last-child {
  margin: 0;
}

/*Rahmen Ende*/
.content li:before {
  color: #d7031c;
  content: "\f101 ";
  font-family: 'FontAwesome';
  font-size: 1em;
  margin: 0 12px 0 -31px;
}

.content ul {
  display: inline-block;
  margin-left: 2.3em;
  margin-bottom: 1em;
}

.content li {
  margin-bottom: 1em;
}

.slider .important-mobile p {
  display: none;
}

@media only screen and (min-width: 1201px) {
  .slider {
    height: 443px;
  }
}

@media only screen and (max-width: 1200px) {
  body {
    font-size: 16.8px;
    line-height: 1.42em;
  }
  .flex-caption {
    left: 8%;
  }
  .flex-caption p {
    font-size: 2.3vw;
    padding: 1.2vw;
  }
  .slider .important p, .slider .important-mobile p {
    width: 21.9vw;
  }
}

@media only screen and (max-width: 1050px) {
  .box1, .box2, .box3, .box4 {
    font-size: 1.8vw;
    line-height: 2.4vw;
  }
  .rettungsring {
    margin-right: 1.2%;
    width: 10%;
  }
  .footer-1 {
    margin-right: 2.5%;
    width: 37.5%;
  }
  .footer-2 {
    margin-right: 2.5%;
    width: 28.75%;
  }
  .footer-3 {
    margin-right: 0;
    width: 28.75%;
  }
  .footer-4 {
    clear: left;
    margin-right: 2.5%;
    width: 37.5%;
    margin-top: 2em;
  }
  .footer-5 {
    margin-right: 2.5%;
    width: 28.75%;
    margin-top: 2em;
  }
  .footer-6 {
    margin-right: 0;
    width: 28.75%;
    margin-top: 2em;
  }
  .flex-caption {
    top: 5em;
  }
}

@media only screen and (max-width: 950px) {
  .navi li {
    margin-right: 0;
  }
}

@media only screen and (max-width: 800px) {
  .flex-caption {
    top: 3.5em;
  }
  .logo {
    margin: 0 auto;
    text-align: center;
    width: 100%;
  }
  .logo img {
    display: inline;
    margin: 0 auto;
  }
  .navi {
    margin-bottom: 0.6em;
    margin-top: 2em;
    text-align: center;
    width: 100%;
  }
  .mitte {
    display: block;
  }
  .content {
    flex: initial;
    max-width: initial;
    margin-right: 0;
    width: auto;
  }
  .rechte-spalte {
    flex: initial;
    max-width: initial;
    width: auto;
  }
  .powermail_field.powermail_submit {
    float: left;
  }
  .footer-1 {
    margin-right: 0;
    width: 100%;
    margin-bottom: 2em;
  }
  .footer-2 {
    margin-right: 2.5%;
    width: 48.75%;
  }
  .footer-3 {
    margin-right: 0;
    width: 48.75%;
  }
  .footer-4 {
    margin-right: 2.5%;
    width: 48.75%;
  }
  .footer-5 {
    margin-right: 0;
    width: 48.75%;
  }
  .footer-6 {
    float: left;
    margin-right: 0;
    width: 50%;
  }
  .slider .important p {
    display: none;
  }
  .slider .important-mobile p {
    display: block;
  }
}

@media only screen and (max-width: 610px) {
  body, html {
    font-size: 16px;
  }
  .navi {
    display: none;
    font-size: 0.89em;
  }
  .flex-direction-nav {
    display: none;
  }
  .background-slider-wrap div > img,
  .slides li > img {
    float: right;
    max-width: 170%;
  }
  .background-slider-wrap > div,
  .slides > li {
    overflow: hidden;
  }
  .flex-viewport {
    max-height: none;
  }
  .flex-active-slide {
    /*z-index: 100000;*/
  }
  .flex-caption {
    bottom: 0;
    left: 0;
    position: static;
    width: 100%;
    overflow: hidden;
  }
  .flex-caption p {
    font-size: 5.5vw;
    margin-bottom: 0;
    padding: 0 3vw 3.9vw;
    text-align: center;
    background: #fff;
  }
  .flex-caption p:first-child {
    padding-top: 3vw;
  }
  .slider .important-mobile p {
    width: 100%;
  }
  .rettungsring {
    left: 5%;
    margin-right: 0;
    margin-top: 11%;
    width: 23%;
  }
  #mobile-header {
    background: none repeat scroll 0 0 #d7031c;
    border-radius: 5px;
    color: #ffffff;
    display: block;
    float: right;
    margin-top: 2.3em;
    text-align: right;
    width: 7.6em;
  }
  #mobile-header a {
    //background: url("../img/stripes.png") no-repeat scroll 93.5% center rgba(0, 0, 0, 0);
    color: #fff;
    display: block;
    font-size: 1.1em;
    font-weight: bold;
    padding: 0.7em 0.9em;
    text-align: left;
    text-decoration: none;
  }
  #mobile-header a:after {
    color: #fff;
    content: "";
    font-family: 'FontAwesome';
    font-size: 1.3em;
    margin: 0 0 0 0.6em;
  }
  .sidr ul li {
    float: none;
  }
  .logo {
    width: 10rem;
  }
  .box1, .box2, .box3, .box4 {
    width: 100%;
    margin: 0;
    font-size: 4vw;
  }
  .boxen .ce-bodytext {
    margin-top: 1vw;
    width: 81%;
    padding-right: 0;
  }
  .boxen .ce-gallery {
    float: left;
    margin: 0.1em 0 0.1em 0.5em;
    width: 7%;
  }
  .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_6, .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_8, .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_11 {
    float: left;
    margin-right: 0;
    width: 100%;
  }
  .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_7,
  .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_10,
  .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_12,
  .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_25,
  .powermail_fieldwrap.powermail_fieldwrap_input.powermail_fieldwrap_26 {
    float: left;
    width: 100%;
  }
  .header-wrap, .mitte, .footer, .boxen {
    width: 92%;
  }
  .logo {
    width: 7.5rem;
  }
  h1, h2, h3, h4, h5 {
    line-height: 1em;
    margin-bottom: 0.5em;
    font-size: 2.4em;
  }
  h1.subheadline, .csc-subheader {
    line-height: 1.1em;
    margin-top: -0.7em;
    font-size: 1.5em;
  }
  h3, h4, h5 {
    font-size: 1.9em;
  }
  .google-translate-element {
    right: 0;
    top: auto;
    bottom: 15px;
  }
  #mobile-header {
    margin-top: 1.1em;
    width: 8.3em;
  }
  #mobile-header a {
    padding: 0.7em 1.2em;
  }
  #mobile-header a:after {
    margin: 0 0 0 0.8em;
  }
  .goog-te-menu-frame {
    top: 115px !important;
  }
}

@media only screen and (max-width: 350px) {
  .footer-1 {
    border-bottom: 1px solid #4da8d8;
    margin-bottom: 1em;
    margin-right: 0;
    padding-bottom: 1em;
    width: 100%;
  }
  .footer-2 {
    margin-right: 0;
    width: 100%;
  }
  .footer-3 {
    margin-right: 0;
    width: 100%;
  }
  .footer-4 {
    border-bottom: 1px solid #4da8d8;
    margin-bottom: 1em;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 1em;
    width: 100%;
  }
  .footer-5 {
    margin-right: 0;
    width: 100%;
    margin: 0;
  }
  .footer-6 {
    float: left;
    margin-right: 0;
    width: 100%;
  }
}

.header-wrap.dynamic {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 11;
  max-width: 100%;
  overflow: hidden;
  height: 0;
}

.header-wrap.dynamic .header {
  margin: 0px auto;
  z-index: 10;
  max-width: 1170px;
  display: block;
  width: 97.4359%;
}

@media only screen and (max-width: 610px) {
  .header-wrap.dynamic {
    display: none;
  }
}

@media only screen and (min-width: 1170px) {
  .rettungsring {
    margin-right: 0;
    width: auto;
    right: calc(50% - 585px);
  }
  .flex-caption {
    left: calc(50% - 585px);
  }
}

/* DSGVO changes */
.powermail_check_outer {
  margin-bottom: 10px;
}

.powermail_fieldwrap_html {
  font-size: 13px;
  margin-bottom: 10px;
}

.cc-window {
  border-top: 1px solid #0082C7;
}


.google-translate {
  &__placeholder {
    position: relative;
    cursor: pointer;
  }

  &__placeholder-text {
    position: absolute;
    top: 100%;
    z-index: 10000000;

    font-size: 16px;
    background-color: white;
    padding: 20px;
    text-align: center;
    line-height: 18px;
    box-shadow: 0 14px 17px rgba(0,0,0,.51);
    display: none;
    left: -80px;
    right: 0;

    @media screen and (min-width: 801px){
      left: -20px;
      right: -20px;
    }

    &--show {
      display: block;
    }
  }

  &__placeholder-image {
    display: none;

    @media screen and (min-width: 801px){
      display: block;
    }

    &--mobile {
      display: block;

      @media screen and (min-width: 801px){
        display: none;
      }
    }
  }
}