.youtube {

  &__placeholder {
    padding-bottom: 57%;
    width: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__placeholder-content {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 22px;

    @media screen and (min-width: 801px){
      font-size: 18px;
      line-height: 22px;
      align-items: flex-start;
      padding-top: 50px;
    }

  }

  &__placeholder-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .4);
    cursor: pointer;
    display: flex;
    transition: .3s;

    @media screen and (min-width: 801px){
      opacity: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__placeholder-icon {
    width: 70px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    display: block;
    height: 100%;

    @media screen and (min-width: 801px){
      width: 120px;
    }
  }
}
